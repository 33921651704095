// // Create two new React components for the forgot password flow with Tailwind CSS

// // ForgotPasswordPage.jsx
// import { useState } from 'react';
// import LeftPanel from '../LogInPage/LeftPanel';
// import { authenticatedRequest } from '../../api';

// const ForgotPasswordPage = () => {
//   const [email, setEmail] = useState('');
//   const [message, setMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [isSuccess, setIsSuccess] = useState(false);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!email) return;

//     try {
//       setIsLoading(true);
//       const response = await authenticatedRequest.post('/auth/forgot-password', { email });
//       setMessage(response.data.message);
//       setIsSuccess(true);
//     } catch (error) {
//       console.error('Forgot password error:', error);
//       setMessage(error.response?.data?.error || 'An error occurred. Please try again.');
//       setIsSuccess(false);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="flex h-screen bg-[#1a1a2e]">
//       <LeftPanel />
//       <div className="flex-1 flex flex-col justify-center p-8 text-white">
//         <h1 className="text-4xl font-bold mb-2">Reset Your Password</h1>
//         <p className="text-gray-400 mb-8">Enter your email address and we'll send you a link to reset your password.</p>
        
//         {message && (
//           <div className={`p-4 rounded-lg mb-6 ${
//             isSuccess 
//               ? 'bg-green-900/20 border border-green-600' 
//               : 'bg-red-900/20 border border-red-600'
//           }`}>
//             {message}
//           </div>
//         )}

//         <form onSubmit={handleSubmit}>
//           <div className="mb-6">
//             <label htmlFor="email" className="block mb-2 text-gray-400">
//               Email address
//             </label>
//             <input
//               type="email"
//               id="email"
//               placeholder="example@email.com"
//               value={email}
//               onChange={(e) => setEmail(e.target.value)}
//               required
//               disabled={isLoading || isSuccess}
//               className="w-full p-3 border-none rounded-lg bg-white/10 text-white"
//             />
//           </div>
//           <button
//             type="submit"
//             className={`w-full p-3 border-none rounded-lg text-base cursor-pointer mb-4 
//               bg-gradient-to-r from-[#ff69b4] to-[#8a2be2] text-white transition-opacity
//               ${(isLoading || isSuccess) ? 'opacity-60 cursor-not-allowed' : 'hover:opacity-90'}`}
//             disabled={isLoading || isSuccess}
//           >
//             {isLoading ? 'Sending...' : 'Send Reset Link'}
//           </button>
//           <p className="text-center mt-4">
//             Remember your password? <a href="/login" className="text-blue-400 no-underline">Sign In</a>
//           </p>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ForgotPasswordPage;


// ForgotPasswordPage.jsx
import LeftPanel from '../LogInPage/LeftPanel';
import ForgotPasswordForm from './ForgotPasswordForm';

const ForgotPasswordPage = () => {
  return (
    <div className="flex h-screen">
      <div className="md:flex hidden flex-1">
        <LeftPanel />
      </div>
      <ForgotPasswordForm />
    </div>
  );
}

export default ForgotPasswordPage;
