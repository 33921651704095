import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import logo from "../../public/peacepal_logo_2.png";

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-[#191b29] flex items-center justify-center p-4">
      <Card className="w-full max-w-4xl bg-[#191b29] text-white">
        <CardHeader className="flex flex-col items-center gap-4 pb-2">
          <div className="flex justify-center">
            <img
              src={logo}
              alt="PeacePal Logo"
              className="h-24 w-auto"
            />
          </div>
          <div className="text-center">
            <h1 className="text-3xl font-bold bg-gradient-to-r from-[#54a3c3] to-[#a16bdd] bg-clip-text text-transparent">
              Terms of Service
            </h1>
          </div>
        </CardHeader>

        <CardBody className="overflow-auto max-h-[70vh]">
          <div className="prose prose-invert max-w-none">
            {/* Replace this with your actual TOS content */}
            <p>
              
PeacePal AI Terms of Service
Effective Date: [12/1/2024]
 Last Revised: [1/25/2025 ]

Service Overview & Agreement
The PeacePal AI websites (“Websites”), mobile applications (“Apps”), and related services (collectively referred to as the “Service”) are operated by PeacePal AI Inc., also doing business as PeacePal AI and PeacePal (collectively referred to herein as “PeacePal,” “we,” “us,” or “our”). Your access to and use of the Service is governed by these Terms and Conditions of Service (“Terms and Conditions”).
By accessing or using any portion of the Service, you confirm that you have read, understood, and agreed to comply with these Terms and Conditions, including any future updates or modifications. PeacePal AI reserves the right to amend, revise, or update these Terms and Conditions at its sole discretion. If changes are made, a notice of the update will be posted on the Website for at least seven (7) days following the revision. The date of the latest update will also be displayed at the bottom and top of these Terms and Conditions. PeacePal AI further retains the right, at any time and at its sole discretion, to modify, enhance, suspend, or terminate any part of the Service, whether on a temporary or permanent basis.
By continuing to access or use the Service after the updated Terms and Conditions take effect, you indicate your acceptance of the new terms. If you do not agree to abide by the revised Terms and Conditions, you are not authorized to use, access, or participate in the Service.
Disclaimer and User Acknowledgement 
Our services are intended to complement—but in no way replace—licensed mental health care or professional medical guidance. Under no circumstances should the insights, suggestions, or content provided by PeacePal AI be interpreted, misconstrued, or relied upon as professional medical advice, diagnosis, or treatment. Only a licensed therapist, counselor, or doctor is qualified to provide such services.


Nature of Services
PeacePal AI offers tools and features designed to support personal growth and promote overall well-being, including
AI-powered chats with custom characters, for engaging conversations .
Goal-setting features to help track personal growth.
Guided meditations designed to reduce stress and improve mindfulness.
A mood tracker for self-monitoring emotional patterns.
Profile management for user customization and progress tracking. 
Limitations of Service Representation
PeacePal AI is not a crisis service and should not be relied upon in situations requiring urgent or individualized therapeutic care.Users experiencing severe emotional distress, thoughts of self-harm, acute mental health challenges, or other complex psychological concerns must seek immediate assistance from licensed mental health professionals or emergency services.
PeacePal AI is not a substitute for professional care provided by licensed mental health or medical practitioners.
User Responsibility
Any interpretation of our services as professional advice or medical guidance is expressly incorrect.
Users must understand and agree that our platform supplements, but does not replace, professional therapeutic or medical services.
By engaging with PeacePal AI’s services, users acknowledge that the platform’s purpose is strictly to support general wellness and self-reflection. PeacePal AI does not provide licensed medical or mental health care, and any interpretation of the platform as such is explicitly disclaimed.
    
AI Data Access and Utilization Provision
In agreement to use the services provided, the User hereby grants PeacePal AI Inc. an irrevocable, comprehensive authorization for its Artificial Intelligence (AI) system to access, analyze, and process all data submitted to or generated within the application, with the following explicit purposes:
 1. Scope of Data Access
The AI system is authorized to comprehensively view, analyze, process, and interpret:
All user-submitted data
Application-generated content
User interaction logs
System performance metrics
Any and all data within the application ecosystem
2. Personalization Services
The AI is expressly permitted to:
Generate individualized user experiences
Develop adaptive application interfaces
Create personalized feature recommendations
Optimize user interaction pathways
3. Operational Insights
The AI may:
Generate analytical insights
Develop predictive models
Identify usage patterns and trends
Support strategic decision-making processes
4. System Performance Enhancement
The AI is authorized to:
Continuously improve application functionality
Identify and resolve performance inefficiencies
Implement proactive system optimizations
Enhance overall service quality
Legal Acknowledgment
By accessing the service, the User acknowledges and agrees that:
All data processing is conducted at the sole discretion of PeacePal AI Inc.
No additional user consent is required for AI data utilization
The AI's data access and processing rights are comprehensive and unrestricted
Intellectual Property Provision
All insights, reports, recommendations, and derivative works generated by the AI system shall be the exclusive intellectual property of PeacePal AI Inc. with all associated rights, titles, and interests fully transferred and assigned.

Guidelines for Responsible Use
You are responsible for your use of the Service and agree to:
Comply with all applicable laws and regulations.
Use the Service only for its intended purposes.
Avoid any actions that may harm, disrupt, or interfere with the Service or other users’ experiences.
We strictly forbid any behaviors that could negatively affect others or our platform. Prohibited activities include, but are not limited to:
Sharing or uploading content that is harmful, offensive, or infringes upon others' rights.
Attempting to reverse-engineer or bypass security measures.
Using the Service for unauthorized commercial purposes.

Trademarks
“PeacePal” or “PeacePal AI” and associated logos are trademarks Exclusively owned by PeacePal AI Inc. Unauthorized use of our trademarks is strictly prohibited without explicit permission.

Your Responsibilities and Assurances
By accessing and using the Service, you affirm and guarantee that your use will comply with these Terms and all relevant laws, regulations, and rules of the United States, as well as any other applicable jurisdictions. This includes, but is not limited to, regulations governing online behavior, permissible content, and the transmission of data or information exported from the United States or your country of residence. Additionally, you confirm and warrant that any material you submit to the Service, including but not limited to Activity Materials and Content, is your original creation or that you hold the necessary rights to grant us a license to use such material or to assign those rights as described herein.
You further represent and warrant that you are not:
Incorporated, organized, or operating from a jurisdiction or territory subject to comprehensive U.S. economic or trade sanctions (e.g., embargoed regions);
Listed as a prohibited or restricted party on official U.S. government lists, such as the U.S. Treasury Department's Specially Designated Nationals and Blocked Persons List; or
Otherwise the subject of U.S. sanctions.

User Content and Licensing
When submitting any ratings, reviews, information, data, text, photographs, audio files, audiovisual content, or other materials to the Service (collectively referred to as “Content”), you grant us a fully paid, royalty-free, perpetual, irrevocable, global, non-exclusive, transferable, and sublicensable license. This license allows us to use, reproduce, copy, modify, adapt, merge, distribute, publicly display, and create derivative works from your Content, integrate the Content into other works, and sublicense it through multiple tiers. You acknowledge that this license remains effective indefinitely and cannot be revoked once the Content has been submitted.
You affirm that you either own the Content you provide or have secured all necessary rights and permissions for its use as outlined in these Terms and Conditions. By submitting Content, you acknowledge that other users may access it and that neither they nor we have any obligation to maintain its confidentiality.
You are prohibited from uploading, displaying, or providing any Content on or through the Service that:
Is defamatory, libelous, abusive, threatening, harassing, hateful, or offensive, or that otherwise violates any applicable law or infringes the rights of any third party, including intellectual property, privacy, publicity, or other proprietary rights.
In our sole discretion, it is objectionable, restricts or prevents other users from utilizing the Service, or could expose us or our users to harm or liability.
Violates the guidelines or policies established for the Service.
We reserve the right to remove or refuse to display any Content that breaches these provisions or is deemed inappropriate.

App Usage 
Under the provisions of these Terms and Conditions, you are granted a non-transferable, non-exclusive license to download, install, and use one copy of each App in object code form on a compatible device that you own or control. You may not attempt to derive the source code of any portion of the App, allow others to do so, or engage in reverse engineering, decompiling, disassembling, or translating any part of the App.
All intellectual property rights and related interests in the Apps, including any updates, modifications, or corrections, are owned and retained by us and our licensors.
If you download the Apps from the Apple App Store, the following terms apply:
These Terms and Conditions are an agreement solely between you and us, not Apple.
Apple is not responsible for the Apps or their content.
Your use of the Apps must comply with the Apple App Store Terms of Service.
Apple has no obligation to provide maintenance or support for the Apps.
In the event that an App fails to meet an applicable warranty, you may notify Apple for a refund of the purchase price, if any. Apple has no further warranty obligations with respect to the Apps.
Apple is not responsible for addressing any claims related to your use of the Apps, including but not limited to:
Product liability claims.
Claims of non-compliance with legal or regulatory standards.
Claims arising under consumer protection or similar laws.
Your use of any App must comply with applicable third-party terms of agreement.
Subscriptions and Virtual Items
Automatic Renewal
 Subscriptions purchased through the Service automatically renew unless canceled at least 24 hours before the end of the current billing cycle. If you do not wish your subscription to renew automatically, or if you want to change or terminate your subscription, you will need to log in to your PeacePal account and follow instructions to terminate or change your subscription, even if you have deleted your account.


Virtual Items
Virtual items or currency within the Service are for use exclusively within the App. These items:
Have no real-world monetary value.
Can only be purchased in the App
Cannot be transferred, sold, or refunded.
We retain the right to manage, regulate, modify, or discontinue Virtual Items at any time, with or without prior notice. Pricing for Virtual Items may also be adjusted at our sole discretion, and additional Virtual Items may be offered for separate fees.
We shall not be held liable to you or any third party for any actions taken to manage or modify Virtual Items, including their pricing, availability, or removal from the Service
Pricing and Taxes
You agree to pay all fees and applicable taxes incurred by you or anyone using a PeacePal AI account registered to you. PeacePal AI reserves the right to revise the pricing for goods, subscriptions, and services offered through the Service at any time, at its sole discretion.
All information you provide in connection with a purchase or transaction, or any monetary transaction interaction with the Service, must be accurate, complete, and current. You agree to provide a valid payment method at the time of purchase and to promptly update your payment information as necessary to avoid interruptions in your use of the Service.
You authorize PeacePal AI or its designated payment processor to charge the payment method you provided for all applicable fees and taxes. Fees are charged at the prices in effect at the time the charge is incurred. You will also be responsible for paying any applicable taxes, including but not limited to value-added tax (VAT), sales tax, or similar charges, arising from any purchase, transaction, or other monetary interaction through the Service.
PeacePal AI may suspend or terminate your account if payment cannot be processed, if payment is overdue, or if unauthorized charges are made on your payment method. You are responsible for resolving any issues with your payment method provider, including disputes over charges.
All fees are quoted and charged in the currency specified at the time of purchase. PeacePal AI is not responsible for any currency conversion fees, bank fees, or other charges imposed by your payment provider.
Certain promotional discounts or limited-time offers may apply to the fees charged for specific products or services. Such discounts are subject to the terms of the promotion and may not be combined unless explicitly stated.
Refunds, if any, are subject to our Refund Policy and are at PeacePal AI's sole discretion. If a refund is issued, you acknowledge that additional fees, including transaction or currency exchange fees, may be deducted by third-party payment processors.
By using the Service, you agree to these payment terms and acknowledge that PeacePal AI may update or modify these terms as needed. Any changes to pricing or fees will be communicated to you in advance, and your continued use of the Service constitutes acceptance of such changes.
Third-Party Payment Handling
All transactions made through the Service are processed via third-party payment processors. PeacePal AI is not responsible for errors, outages, or actions of payment processors, including unauthorized charges or disruptions in service. Users are encouraged to review the terms and privacy policies of these processors.
Payments and Refunds
Payments made for subscriptions or in-app purchases are nonrefundable. No refunds will be provided for partially used subscription periods or virtual items. Refunds or credits may be issued at PeacePal AI’s sole discretion on a case-by-case basis, and such issuance does not obligate future refunds under similar circumstances.
Advertisements and External Links
The Service may contain links to third-party websites or services. PeacePal AI does not endorse or control these external resources and is not liable for any issues arising from their use. Additionally, PeacePal AI may feature advertisements, which are subject to change at our discretion. By using the Service, you consent to the display of such advertisements.
Protection Against Claims
Indemnification
You agree to defend, indemnify, and hold harmless PeacePal AI, including its directors, officers, employees, contractors, agents, suppliers, licensors, successors, and assigns, against any and all losses, claims, obligations, liabilities, damages, costs, or expenses (including reasonable attorneys' fees). This applies to:
Your use or misuse of the Service.
False statements or misrepresentations made by you under these Terms.
Violations of these Terms and Conditions.
Claims arising from content or services provided, including but not limited to inaccuracies or defects in translations or recommendations.
Limitation of Liability
 To the maximum extent permitted by law, PeacePal AI, along with its affiliates, directors, officers, employees, and agents, will not be responsible for any:
Indirect, incidental, or consequential damages.
Special or punitive damages.
Loss of profits, data, or business opportunities.
Intangible losses resulting from the use or inability to use the Service, even if PeacePal AI has been advised of the possibility of such damages.
In all cases, PeacePal AI’s cumulative liability for any claim will not exceed the total amount paid by you for the Service in the 12 months preceding the claim.
This limitation applies to all claims, whether arising from the use of the Service, reliance on its content, or interactions with PeacePal AI. If a specific part of this limitation is unenforceable, PeacePal AI’s liability will be restricted to the fullest extent allowed under applicable law.
By accessing or using the Service, you agree to these indemnification and liability terms, which ensure the fair allocation of risks associated with using PeacePal AI.
Account Suspension and Termination
PeacePal AI reserves the right to terminate or suspend your access to the Service at any time, with or without notice, for any reason, including violation of these Terms. You may terminate your account at any time through the Service. Sections related to proprietary rights, disclaimers, limitation of liability, and dispute resolution shall survive termination.
Ownership of Service Content
All content available through the Service, including text, graphics, software, and other materials, is owned or licensed by PeacePal AI. Users may not reproduce, modify, or distribute any part of the Service except as explicitly authorized.
By using the Service, you grant PeacePal AI an irrevocable license to any user-generated content uploaded to or shared within the platform.

Privacy Policy
Use of the Service is governed by our Privacy Policy, which is available within the App and on our website. By using the Service, you agree to the practices described therein.
Governing Law and Legal Venue
The PeacePal AI Terms and Conditions, along with any related disputes or claims, are governed by the laws of the State of Delaware, United States. The application of any conflict of laws principles that would result in the application of laws from another jurisdiction is expressly excluded.
If any disputes arise that are not subject to arbitration or fall outside of the arbitration agreement, they must be filed and resolved exclusively in the state or federal courts located in Sussex County, Delaware. By using the Service, both parties agree to submit to the jurisdiction of these courts and waive any objections based on venue or forum inconvenience.
Arbitration
Mandatory Arbitration & Waiver of Lawsuits
By agreeing to these Terms and using the Service, you expressly acknowledge and agree that any disputes or claims arising from or related to your use of the Service must be resolved exclusively through binding arbitration on an individual basis. This means:
You waive your right to file a lawsuit against PeacePal AI in any court of law.
You cannot participate in a class-action lawsuit or collective arbitration against PeacePal AI.
Arbitration shall be conducted by a neutral arbitrator in accordance with the rules of the American Arbitration Association (AAA) or a similar dispute resolution organization.
The arbitration process will be confidential and the decision will be final and binding on all parties.
If you wish to opt out of this arbitration agreement, you must notify us in writing within 30 days of first using the Service.
This arbitration provision applies to the fullest extent permitted by law and supersedes any conflicting provisions in applicable legal jurisdictions. If any part of this provision is found to be unenforceable, the remaining terms shall still apply. By using the Service, you irrevocably waive the right to a trial by jury and agree that arbitration is the exclusive means of dispute resolution. 
Miscellaneous
These Terms constitute the entire agreement between you and PeacePal AI regarding the Service. If any provision is deemed unenforceable, the remaining provisions shall remain in effect. Failure to enforce any right does not constitute a waiver of such rights.


            </p>
          </div>
        
        </CardBody>
      </Card>
    </div>
  );
};

export default TermsOfService;