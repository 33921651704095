
// ResetPasswordForm.jsx
import { useState, useEffect } from "react";
import { resetPassword } from "../../api";
import { useNavigate, useSearchParams } from 'react-router-dom';
import WalletAuthButton from '../WalletAuthButton/WalletAuthButton';

const ResetPasswordForm = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      setMessage('Invalid or missing reset token. Please request a new password reset link.');
    }
  }, [token]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Validation
    if (!token) {
      setMessage('Invalid or missing reset token. Please request a new password reset link.');
      return;
    }
    
    if (password !== confirmPassword) {
      setMessage('Passwords do not match.');
      return;
    }
    
    if (password.length < 8) {
      setMessage('Password must be at least 8 characters long.');
      return;
    }
    
    try {
      setIsLoading(true);
      const response = await resetPassword(token, password);
      
      setMessage(response.message);
      setIsSuccess(true);
      
      // Redirect to login page after 3 seconds
      setTimeout(() => {
        navigate('/login');
      }, 3000);
      
    } catch (error) {
      console.error('Reset password error:', error);
      setMessage(error.response?.data?.error || 'An error occurred. Please try again or request a new reset link.');
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-form">
      <h1>Create New Password 🔐</h1>
      <p>Please enter your new password below</p>
      
      {message && (
        <div className={`p-4 rounded mb-4 ${isSuccess ? 'bg-green-800/20 text-green-300' : 'bg-red-800/20 text-red-300'}`}>
          {message}
          {isSuccess && <p className="mt-2">Redirecting to login page...</p>}
        </div>
      )}
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="password">New Password</label>
          <input 
            type="password" 
            id="password" 
            placeholder="Min 8 chars" 
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            style={{ fontSize: '24px' }}
            required
            disabled={isLoading || isSuccess || !token}
          />
        </div>
        
        <div className="form-group">
          <label htmlFor="confirmPassword">Confirm Password</label>
          <input 
            type="password" 
            id="confirmPassword" 
            placeholder="Re-enter password" 
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            style={{ fontSize: '24px' }}
            required
            disabled={isLoading || isSuccess || !token}
          />
        </div>
        
        <button 
          type="submit" 
          className="sign-in-button"
          disabled={isLoading || isSuccess || !token}
        >
          {isLoading ? 'Updating...' : 'Reset Password'}
        </button>
      </form>
      
      <p className="sign-up-link">Remember your password? <a href="/login">Sign In</a></p>
    </div>
  );
}

export default ResetPasswordForm;
