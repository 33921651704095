// // ResetPasswordPage.jsx
// import { useState, useEffect } from 'react';
// import { useNavigate, useSearchParams } from 'react-router-dom';
// import LeftPanel from '../LogInPage/LeftPanel';
// import { authenticatedRequest } from '../../api';

// const ResetPasswordPage = () => {
//   const [password, setPassword] = useState('');
//   const [confirmPassword, setConfirmPassword] = useState('');
//   const [message, setMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(false);
//   const [isSuccess, setIsSuccess] = useState(false);
//   const [searchParams] = useSearchParams();
//   const token = searchParams.get('token');
//   const navigate = useNavigate();

//   useEffect(() => {
//     if (!token) {
//       setMessage('Invalid or missing reset token. Please request a new password reset link.');
//     }
//   }, [token]);

//   const handleSubmit = async (e) => {
//     e.preventDefault();
    
//     // Validation
//     if (!token) {
//       setMessage('Invalid or missing reset token. Please request a new password reset link.');
//       return;
//     }
    
//     if (password !== confirmPassword) {
//       setMessage('Passwords do not match.');
//       return;
//     }
    
//     if (password.length < 8) {
//       setMessage('Password must be at least 8 characters long.');
//       return;
//     }
    
//     try {
//       setIsLoading(true);
//       const response = await authenticatedRequest.post('/auth/reset-password', {
//         token,
//         newPassword: password
//       });
      
//       setMessage(response.data.message);
//       setIsSuccess(true);
      
//       // Redirect to login page after 3 seconds
//       setTimeout(() => {
//         navigate('/login');
//       }, 3000);
      
//     } catch (error) {
//       console.error('Reset password error:', error);
//       setMessage(error.response?.data?.error || 'An error occurred. Please try again or request a new reset link.');
//       setIsSuccess(false);
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   return (
//     <div className="flex h-screen bg-[#1a1a2e]">
//       <LeftPanel />
//       <div className="flex-1 flex flex-col justify-center p-8 text-white">
//         <h1 className="text-4xl font-bold mb-2">Create New Password</h1>
//         <p className="text-gray-400 mb-8">Please enter your new password below.</p>
        
//         {message && (
//           <div className={`p-4 rounded-lg mb-6 ${
//             isSuccess 
//               ? 'bg-green-900/20 border border-green-600' 
//               : 'bg-red-900/20 border border-red-600'
//           }`}>
//             {message}
//             {isSuccess && <p className="mt-2">Redirecting to login page...</p>}
//           </div>
//         )}

//         <form onSubmit={handleSubmit}>
//           <div className="mb-6">
//             <label htmlFor="password" className="block mb-2 text-gray-400">
//               New Password
//             </label>
//             <input
//               type="password"
//               id="password"
//               placeholder="Min 8 characters"
//               value={password}
//               onChange={(e) => setPassword(e.target.value)}
//               required
//               disabled={isLoading || isSuccess || !token}
//               className="w-full p-3 border-none rounded-lg bg-white/10 text-white"
//             />
//           </div>
//           <div className="mb-6">
//             <label htmlFor="confirmPassword" className="block mb-2 text-gray-400">
//               Confirm Password
//             </label>
//             <input
//               type="password"
//               id="confirmPassword"
//               placeholder="Re-enter password"
//               value={confirmPassword}
//               onChange={(e) => setConfirmPassword(e.target.value)}
//               required
//               disabled={isLoading || isSuccess || !token}
//               className="w-full p-3 border-none rounded-lg bg-white/10 text-white"
//             />
//           </div>
//           <button
//             type="submit"
//             className={`w-full p-3 border-none rounded-lg text-base cursor-pointer mb-4 
//               bg-gradient-to-r from-[#ff69b4] to-[#8a2be2] text-white transition-opacity
//               ${(isLoading || isSuccess || !token) ? 'opacity-60 cursor-not-allowed' : 'hover:opacity-90'}`}
//             disabled={isLoading || isSuccess || !token}
//           >
//             {isLoading ? 'Updating...' : 'Reset Password'}
//           </button>
//           <p className="text-center mt-4">
//             Remember your password? <a href="/login" className="text-blue-400 no-underline">Sign In</a>
//           </p>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default ResetPasswordPage;


// ResetPasswordPage.jsx
import LeftPanel from '../LogInPage/LeftPanel';
import ResetPasswordForm from './ResetPasswordForm';

const ResetPasswordPage = () => {
  return (
    <div className="flex h-screen">
      <div className="md:flex hidden flex-1">
        <LeftPanel />
      </div>
      <ResetPasswordForm />
    </div>
  );
}

export default ResetPasswordPage;