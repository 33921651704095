// ForgotPasswordForm.jsx
import { useState } from "react";
import { requestPasswordReset } from "../../api";
import { useNavigate } from 'react-router-dom';
import WalletAuthButton from '../WalletAuthButton/WalletAuthButton';

const ForgotPasswordForm = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email) return;

    try {
      setIsLoading(true);
      const response = await requestPasswordReset(email);
      setMessage(response.message);
      setIsSuccess(true);
    } catch (error) {
      console.error('Forgot password error:', error);
      setMessage(error.response?.data?.error || 'An error occurred. Please try again.');
      setIsSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="login-form">
      <h1>Reset Password 🔐</h1>
      <p>Enter your email to receive a password reset link</p>
      
      {message && (
        <div className={`p-4 rounded mb-4 ${isSuccess ? 'bg-green-800/20 text-green-300' : 'bg-red-800/20 text-red-300'}`}>
          {message}
        </div>
      )}
      
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email address</label>
          <input 
            type="email" 
            id="email" 
            placeholder="example@email.com" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            style={{ fontSize: '24px' }}
            required
            disabled={isLoading || isSuccess}
          />
        </div>
        
        <button 
          type="submit" 
          className="sign-in-button"
          disabled={isLoading || isSuccess}
        >
          {isLoading ? 'Sending...' : 'Send Reset Link'}
        </button>
      </form>
      
      <WalletAuthButton mode="login" />
      <p className="sign-up-link">Remember your password? <a href="/login">Sign In</a></p>
    </div>
  );
}

export default ForgotPasswordForm;
