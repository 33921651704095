import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, CardHeader } from "@nextui-org/react";
import logo from "../../public/peacepal_logo_2.png";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-[#191b29] flex items-center justify-center p-4">
      <Card className="w-full max-w-4xl bg-[#191b29] text-white">
        <CardHeader className="flex flex-col items-center gap-4 pb-2">
          <div className="flex justify-center">
            <img
              src={logo}
              alt="PeacePal Logo"
              className="h-24 w-auto"
            />
          </div>
          <div className="text-center">
            <h1 className="text-3xl font-bold bg-gradient-to-r from-[#54a3c3] to-[#a16bdd] bg-clip-text text-transparent">
              Privacy Policy
            </h1>
          </div>
        </CardHeader>

        <CardBody className="overflow-auto max-h-[70vh]">
          <div className="prose prose-invert max-w-none">
            {/* Replace this with your actual Privacy Policy content */}
            <p>
            Privacy Policy
At PeacePal AI Inc. We are committed to protecting your privacy with rigorous security measures and responsible data handling practices. This Privacy Policy explains how we collect, use, and safeguard your personal information when you use our app. By accessing or using PeacePal AI, you agree to the practices outlined in this policy.
1. Information We Collect
To ensure a seamless and personalized experience, we collect data necessary for improving our services while maintaining strict security measures. This includes your username, email address, chat logs, and prompts to refine interactions and enhance AI performance. Additionally, we collect your age to ensure compliance with applicable regulations and provide a safe user experience. We also gather usage data, such as app activity, device information, operating system, app version, and IP address for security, fraud prevention, and analytics. These measures help us continuously improve functionality while keeping your information secure.
2. How We Use Your Information
We use your data to personalize and improve your experience, provide customer support, notify you of updates, analyze trends, and optimize app performance. Additionally, data is used to maintain security, prevent fraud, and ensure compliance with relevant legal obligations.
3. Data Sharing
We do not sell or rent personal information. However, data may be shared with trusted third-party service providers that assist with hosting, payment processing, analytics, and security. We may also disclose information if required by law or to protect the rights, safety, and integrity of PeacePal AI, its users, or others. In the case of a merger, acquisition, or asset sale, your information may be transferred to the new entity.
4. Data Security
We implement industry-standard encryption and security measures to protect your data from unauthorized access, disclosure, or loss. While we strive to maintain the highest security standards, no digital transmission or storage method is completely foolproof. Rest assured, we are committed to continuously improving our security framework to safeguard your information.
5. Your Rights
Depending on your location, you may have the right to access or request a copy of your data, correct inaccuracies, request deletion, restrict processing, or withdraw consent for specific uses. To exercise these rights, contact us at support@peacepal.ai
6. Data Retention
We retain personal data only as long as necessary to provide our services and comply with legal obligations. If you choose to delete your account, we will securely delete or anonymize your data unless legally required to retain it.
7. Cookies and Tracking
PeacePal AI uses cookies and similar technologies to improve functionality, enhance user experience, and analyze trends. You can manage cookie preferences through your device settings.
8. Third-Party Links
Our app may contain links to third-party services. We are not responsible for their privacy practices and recommend reviewing their policies before providing any personal information.
9. Changes to this Privacy Policy
We may update this policy periodically to reflect changes in practices or legal requirements. Significant updates will be communicated, and we encourage users to review this policy regularly.
For questions, contact us at support@peacepal.ai
            </p>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default PrivacyPolicy;